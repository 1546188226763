/* HIDE RADIO */
.mood[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.mood[type=radio] + span {
  cursor: pointer;
}

/* CHECKED STYLES */
.mood[type=radio]:checked + span {
  /* outline: 2px solid #999; */
  /* background: green;
  border-radius: 100%;
  width: 60px;
  display: block; */
  font-size: 45px !important;
}