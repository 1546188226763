@media screen and (max-width: 768px) {
    .box-outlet{
        width: 100vw !important;
    }
    #kanban {
        scroll-snap-type: x mandatory;
    }
    .kanban {
        /* width: 200vw !important; */
        width: 100vw !important;
    }

    .grid-board{
        width: 100vw !important;
    }

    .sidebar-drawer{
        width: unset !important;
    }

    .toolbar-board{
        width: 100% !important;
    }

    .progress-percentage-kanban{
        display: none;
    }
    #root{
        overflow-x: hidden;
    }

    .box-taskmodal{
        padding: 0rem 1rem 1rem 1rem;
    }

    .final-score-kpi {
        margin-top: 15px;
    }

    .select-division-kpi{
        width: calc(100vw - 85vw) !important;
    }

    .dash-kpi-percent-text {
        font-size: 25px !important;
    }

    .dash-kpi-title-text {
        font-size: 1.1rem !important;
    }
}

.dash-kpi-percent-text {
    font-size: 42px;
}

.dash-kpi-title-text {
    font-size: 1.25rem;
}

.ck.ck-editor{
    width: 100% !important;
}

.select-division-kpi{
    width: 100%;
}

.final-score-kpi {
    margin-top: -37px,
}

body {
    background-color: #121212 !important;
}

.box-taskmodal{
    padding: 0rem 2rem 2rem 2rem;
}