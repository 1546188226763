@font-face {
    font-family: 'CustomFont';
    src: 
         url('../assets/fonts/Zian.ttf') format('truetype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
  }
  
  body {
    font-family: 'CustomFont', sans-serif;
  }